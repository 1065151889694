import React from "react";

const Skills = () => {
  return (
    <>
    </>
  );
};

export default Skills;
